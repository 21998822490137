<script setup lang="ts">
import { useToggle } from "@vueuse/core";
import VText from "@magnit/core/src/components/VText/VText.vue";
import VIcon from "@magnit/icons/src/components/VIcon/VIcon.vue";
import SuccessIcon from "@magnit/icons/src/assets/svg/icons/base/24-system-success.svg";
import WarningIcon from "@magnit/icons/src/assets/svg/icons/base/24-system-warning.svg";
import type { IToastQueue, IToastsProps } from "~/components/VToasts/VToasts.types";

const QUEUE: IToastQueue[] = [];
const LIMIT = 3;
const CONTAINER_CLASS = "toast__container";

const props = withDefaults(defineProps<IToastsProps>(), {
  duration: 4000,
});

const el = ref();
const elContainer = ref();
const [active, toggle] = useToggle();

const setQueue = () => {
  if (QUEUE.length >= LIMIT) {
    const firstElement = QUEUE.shift() as IToastQueue;
    clearTimeout(firstElement.timer);
    firstElement.deactivate();
  }
  const timer = window.setTimeout(function () {
    clearTimeout(timer);
    toggle(false);
  }, props.duration);
  return {
    timer,
    deactivate: () => {
      toggle(false);
    },
  };
};
QUEUE.push(setQueue());

onBeforeMount(() => {
  const container = document.querySelector(`.${CONTAINER_CLASS}`);
  if (container) {
    elContainer.value = container;
    return;
  }
  const containerNew = document.createElement("div");
  containerNew.classList.add(CONTAINER_CLASS);
  elContainer.value = containerNew;
  document.body.appendChild(containerNew);
});

onMounted(() => {
  elContainer.value.appendChild(el.value);
  toggle(true);
});
</script>

<template>
  <div ref="el">
    <transition name="toast_fade">
      <div v-if="active" class="toast" @click="toggle(false)">
        <div class="toast__inner">
          <VIcon>
            <WarningIcon v-if="type === 'warning'" class="toast__icon-warning" />
            <SuccessIcon v-if="type === 'success'" class="toast__icon-success" />
          </VIcon>

          <div class="toast__text">
            <VText color="invert-primary" font="body-small-accent-low">
              {{ props.text }}
            </VText>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<style lang="postcss">
.toast__container {
  position: fixed;
  top: 32px;
  left: 0;
  width: 100%;
  z-index: var(--pl-z-index-toast);
}

.toast {
  margin-bottom: 8px;

  &__inner {
    display: flex;
    margin: 0 20px auto;
    padding: 12px;
    max-width: 100%;
    background-color: var(--pl-toast-background-default);
    box-shadow: var(--pl-shadow-xl);
    border-radius: var(--pl-toast-radius-s);
    cursor: pointer;
  }

  &__text {
    margin-left: 12px;
    word-break: break-word;
  }
}

.toast_fade-enter-active,
.toast_fade-leave-active {
  transition: opacity 0.3s ease;

  & .toast__inner {
    transition: transform 0.3s ease;
    transform: translateY(0);
  }
}

.toast_fade-enter-from,
.toast_fade-leave-to {
  opacity: 0;

  & .toast__inner {
    transform: translateY(-100%);
  }
}

@media (--pl-viewport-from-m) {
  .toast__inner {
    margin: 0 32px auto;
  }
}

@media (--pl-viewport-from-l) {
  .toast__inner {
    margin: 0 auto;
    max-width: fit-content;
  }
}
</style>
